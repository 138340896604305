import React from "react"
import { graphql } from "gatsby"
import ImmersionLayout from "../layouts/immersionLayout"
import SEO from "../components/seo"
import InOutLink from "../components/InOutLink"

class ImmersionPageTemplate extends React.Component {
  render() {
    const post = this.props.data.wordpressPage
    const allPosts =
      this.props.data.allWordpressPost != null
        ? this.props.data.allWordpressPost.edges
        : []
    // const postTitle = post.title
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <ImmersionLayout
        location={this.props.location}
        title={siteTitle}
        backgroundColor="#FFFFFF"
        subtitle={"HANDS"}
      >
        {/*<SEO*/}
        {/*  title={post.title}*/}
        {/*  description={post.excerpt}*/}
        {/*/>*/}
        <article
          className={`post-content page-template size-immersion no-image full-width`}
        >
          <div className="post-content-body">
            <figure className={`wp-block-image size-full `}>
              {/*<iframe id="ytplayer" type="text/html" className={`.video-fill-screen`}*/}
              {/*        src="https://www.youtube.com/embed/CHVLFadjHgM?autoplay=1&controls=0&fs=0&loop=1&modestbranding=1&playsinline=1&color=white&iv_load_policy=3"*/}
              {/*        frameBorder="0" allowFullScreen/>*/}
              {/*<VideoPlayer url={`https://www.youtube.com/embed/CHVLFadjHgM?autoplay=1&controls=0&fs=0&loop=1&modestbranding=1&playsinline=1&color=white&iv_load_policy=3`}></VideoPlayer>*/}
            </figure>
          </div>
          <div className={`post-feed fill-screen `}>
            {/*<Logo className="size-large"></Logo>*/}
          </div>

          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />

          {/*<div*/}
          {/*  className="post-content-body"*/}
          {/*  // dangerouslySetInnerHTML={{ __html: post.content }}*/}
          {/*>*/}
          {/*  <code>*/}
          {/*    <ul>*/}
          {/*      {allPosts.map(({ node }, index) => (*/}
          {/*        <li>*/}
          {/*          <span dangerouslySetInnerHTML={{ __html: node.title }} />*/}
          {/*        </li>*/}
          {/*      ))}*/}
          {/*    </ul>*/}
          {/*  </code>*/}
          {/*</div>*/}

          <footer className="post-content-footer"></footer>
        </article>
      </ImmersionLayout>
    )
  }
}

export default ImmersionPageTemplate

export const immersionPageQuery = graphql`
  query ImmersionPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }

    wordpressPage(slug: { eq: $slug }) {
      title
      excerpt
      content
      date(formatString: "MM.DD.YYYY")
    }
    allWordpressPost(
      filter: { status: { ne: "gatsby_placeholder" } }
      sort: { fields: [title], order: DESC }
      limit: 200
    ) {
      edges {
        node {
          id
          title
          date(formatString: "MM.DD.YYYY")
          slug
          sticky
          tags {
            name
          }
          categories {
            name
          }
          acf {
            artist
            page_video
            redirect
          }
        }
      }
    }
  }
`
